import React from 'react';
import { useSpring, useSprings, animated, config } from 'react-spring';
import { Stylable } from '@content/types/general';
import { WaypointDependent } from './AnimationTriggerWaypoint';

interface Props extends Stylable, WaypointDependent {}

const strokeDasharrays = [500];
const initialDelay = 1000;
const stepsDelay = 500;

export default (props: Props) => {
    // lines animation
    const [animLines, animLinesSet] = useSprings(strokeDasharrays.length, i => ({
        from: { strokeDashoffset: 1 * strokeDasharrays[i] },
        // config: config.slow,
    }));
    animLinesSet(i => ({
        strokeDashoffset: 0,
        delay: initialDelay + i * stepsDelay,
    }));

    // dots animation
    const [animDots, animDotsSet] = useSprings(2, i => ({
        from: { opacity: 0 /*, transform: 'scale(0)'*/ },
        config: config.default,
    }));
    animDotsSet(i => ({
        opacity: props.sectionVisible ? 1 : 0,
        // transform: props.sectionVisible ? 'scale(1)' : 'scale(0)',
        delay: initialDelay + 1000 + i * stepsDelay,
    }));

    // icon animation
    const [animIcons, animIconsSet] = useSprings(3, i => ({
        from: { opacity: 0, transform: 'translate(0%, -2%)' },
        config: config.subtle,
    }));
    animIconsSet(i => ({
        opacity: props.sectionVisible ? 1 : 0,
        transform: props.sectionVisible ? 'translate(0, 0)' : 'translate(0%, -2%)',
        delay: initialDelay + 2000 + i * 300,
    }));

    return (
        <div className={props.className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="456px" height="280px" viewBox="0 0 456 280">
                <mask id="mask-1">
                    <g stroke="#FFFFFF" strokeLinecap="square" strokeDasharray="3" strokeWidth="1">
                        <path
                            d="M435.5,31v-1c0-13.8-11.2-25-25-25h-25.2M435.5,102.3L435.5,82.9M435.5,173.6L435.5,154.2M7.5,251.5h403c13.8,0,25-11.2,25-25v-1"
                            strokeLinecap="square"
                            strokeDasharray="3"
                        ></path>
                    </g>
                </mask>
                <g>
                    <g>
                        <g>
                            <animated.path
                                xmlns="http://www.w3.org/2000/svg"
                                strokeDasharray={strokeDasharrays[0]}
                                strokeDashoffset={animLines[0].strokeDashoffset}
                                d="M435.5,31v-1c0-13.8-11.2-25-25-25h-25.2M435.5,102.3L435.5,82.9M435.5,173.6L435.5,154.2M7.5,251.5h403c13.8,0,25-11.2,25-25v-1"
                                mask="url(#mask-1)"
                                stroke="#949494"
                                fill="none"
                                strokeWidth="2"
                            ></animated.path>
                        </g>
                        <animated.path style={animDots[1]} fill="#AAAAAA" d="M385,10c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5S382.2,10,385,10z"/>
                        <animated.path style={animDots[0]} fill="#AAAAAA" d="M5,257c2.8,0,5-2.2,5-5s-2.2-5-5-5s-5,2.2-5,5S2.2,257,5,257z"/>
                        <animated.g style={animIcons[0]}>
                            <g stroke="#009944"  fill = "none">
                                <path d="M441.4,59h-12.2c-0.8,0-1.6-0.5-1.9-1.3l-2.5-9.7h21l-2.5,9.7C443,58.5,442.2,59,441.4,59z"/>
                                <path d="M438.8,39h1.9c0.8,0,1.6,0.5,1.9,1.3l2.3,4.7h-19l2.3-4.7c0.3-0.8,1-1.3,1.9-1.3h1.9"/>
                                <path d="M445.8,48h-21c-0.6,0-1-0.4-1-1v-1c0-0.6,0.4-1,1-1h21c0.6,0,1,0.4,1,1v1C446.8,47.6,446.3,48,445.8,48z"/>
                                <path d="M430.8,51v5"/>
                                <path d="M435.8,51v5"/>
                                <path d="M440.8,51v5"/>
                            </g>
                            <path fill="#AAAAAA"
                                  fillRule="nonzero" d="M417.9,74.9l-0.5,0.6l0.1,0.1c0.3,0.3,1.2,1,2.6,1c1.6,0,2.6-1,2.6-2.3c0-2.8-4.2-2.2-4.2-4.2     c0-0.7,0.7-1.5,1.8-1.5c1.1,0,1.8,0.7,1.8,0.7l0.4-0.7l-0.1-0.1c-0.2-0.2-1-0.8-2.2-0.8c-1.6,0-2.6,1.1-2.6,2.3     c0,2.6,4.2,2.1,4.2,4.2c0,0.9-0.8,1.5-1.7,1.5C418.8,75.9,417.9,74.9,417.9,74.9z M424.2,76.5h0.8v-3h1l2,3h1l-2.3-3.4v0l2.1-2.6     h-1l-1.8,2.3h-1v-4.7h-0.8V76.5z M430.2,75.1c0,1.3,0.9,1.5,1.4,1.5c0.2,0,0.3,0,0.3,0v-0.7l-0.1,0c0,0,0,0-0.1,0     c-0.3,0-0.7-0.1-0.7-0.9v-6.8h-0.8V75.1z M432.8,73.5c0,1.9,1.4,3.2,3.2,3.2c1.4,0,2.2-0.8,2.2-0.8l-0.4-0.6l-0.1,0.1     c-0.2,0.2-0.9,0.6-1.7,0.6c-1.2,0-2.3-0.8-2.3-2.4h4.6l0-0.3c0-0.1,0-0.1,0-0.1c0-1.5-0.9-2.8-2.5-2.8     C434.1,70.3,432.8,71.5,432.8,73.5z M433.7,72.9c0.2-1.2,1.1-1.8,2.1-1.8c0.9,0,1.7,0.6,1.7,1.8H433.7z M439.9,78.9h0.8V76l0-0.2     c0-0.2,0-0.3,0-0.3h0l0,0.1c0.1,0.2,0.7,1.1,1.9,1.1c1.6,0,2.7-1.2,2.7-3.2c0-1.9-1-3.2-2.6-3.2c-1.6,0-2.1,1.2-2.1,1.2h0l0-0.2     c0-0.1,0-0.2,0-0.3v-0.6h-0.8V78.9z M440.6,73.5c0-1.7,0.9-2.4,2-2.4c1.1,0,1.9,0.9,1.9,2.4c0,1.5-0.9,2.4-1.9,2.4     C441.3,75.9,440.6,74.7,440.6,73.5z M449.8,76.5v-0.7h1.9l0-6.4c0-0.2,0-0.3,0-0.3h0l-0.1,0.1c0,0.1-0.1,0.2-0.3,0.3l-1,1     l-0.5-0.5l1.9-1.9h0.8v7.7h1.9v0.7H449.8z"/>
                        </animated.g>
                        <animated.g style={animIcons[1]}>
                            <g stroke="#009944"  fill = "none">
                                <path d="M441.4,130.3h-12.2c-0.8,0-1.6-0.5-1.9-1.3l-2.5-9.7h21l-2.5,9.7C443,129.8,442.2,130.3,441.4,130.3z"/>
                                <path d="M438.8,110.3h1.9c0.8,0,1.6,0.5,1.9,1.3l2.3,4.7h-19l2.3-4.7c0.3-0.8,1-1.3,1.9-1.3h1.9"/>
                                <path d="M445.8,119.3h-21c-0.6,0-1-0.4-1-1v-1c0-0.6,0.4-1,1-1h21c0.6,0,1,0.4,1,1v1      C446.8,118.9,446.3,119.3,445.8,119.3z"/>
                                <path d="M430.8,122.3v5"/>
                                <path d="M435.8,122.3v5"/>
                                <path d="M440.8,122.3v5"/>
                            </g>
                            <path fill="#AAAAAA"
                                  fillRule="nonzero"  d="M418.8,146.2l-0.5,0.6l0.1,0.1c0.3,0.3,1.2,1,2.6,1c1.6,0,2.6-1,2.6-2.3c0-2.8-4.2-2.2-4.2-4.2     c0-0.7,0.7-1.5,1.8-1.5c1.1,0,1.8,0.7,1.8,0.7l0.4-0.7l-0.1-0.1c-0.2-0.2-1-0.8-2.2-0.8c-1.6,0-2.6,1.1-2.6,2.3     c0,2.6,4.2,2.1,4.2,4.2c0,0.9-0.8,1.5-1.7,1.5C419.7,147.2,418.8,146.2,418.8,146.2z M425.1,147.8h0.8v-3h1l2,3h1l-2.3-3.4v0     l2.1-2.6h-1l-1.8,2.3h-1v-4.7h-0.8V147.8z M431.1,146.4c0,1.3,0.9,1.5,1.4,1.5c0.2,0,0.3,0,0.3,0v-0.7l-0.1,0c0,0,0,0-0.1,0     c-0.3,0-0.7-0.1-0.7-0.9v-6.8h-0.8V146.4z M433.7,144.8c0,1.9,1.4,3.2,3.2,3.2c1.4,0,2.2-0.8,2.2-0.8l-0.4-0.6l-0.1,0.1     c-0.2,0.2-0.9,0.6-1.7,0.6c-1.2,0-2.3-0.8-2.3-2.4h4.6l0-0.3c0-0.1,0-0.1,0-0.1c0-1.5-0.9-2.8-2.5-2.8     C435.1,141.6,433.7,142.8,433.7,144.8z M434.6,144.2c0.2-1.2,1.1-1.8,2.1-1.8c0.9,0,1.7,0.6,1.7,1.8H434.6z M440.8,150.2h0.8     v-2.9l0-0.2c0-0.2,0-0.3,0-0.3h0l0,0.1c0.1,0.2,0.7,1.1,1.9,1.1c1.6,0,2.7-1.2,2.7-3.2c0-1.9-1-3.2-2.6-3.2     c-1.6,0-2.1,1.2-2.1,1.2h0l0-0.2c0-0.1,0-0.2,0-0.3v-0.6h-0.8V150.2z M441.6,144.8c0-1.7,0.9-2.4,2-2.4c1.1,0,1.9,0.9,1.9,2.4     c0,1.5-0.9,2.4-1.9,2.4C442.2,147.2,441.6,146,441.6,144.8z M450.8,147.8c0-0.2,0-0.4,0-0.5c0-3,4.3-3.3,4.3-5.6     c0-0.9-0.7-1.6-1.8-1.6c-1.2,0-1.9,1.2-1.9,1.2l-0.6-0.4l0-0.1c0.2-0.3,1-1.5,2.5-1.5c1.6,0,2.6,1,2.6,2.4c0,2.8-4.3,3.2-4.3,5.5     h4.4v0.7H450.8z"/>
                        </animated.g>
                        <animated.g style={animIcons[2]}>
                            <g stroke="#009944"  fill = "none">
                                <path d="M441.4,201.6h-12.2c-0.8,0-1.6-0.5-1.9-1.3l-2.5-9.7h21l-2.5,9.7C443,201.1,442.3,201.6,441.4,201.6z"/>
                                <path d="M438.8,181.6h1.9c0.8,0,1.6,0.5,1.9,1.3l2.3,4.7h-19l2.3-4.7c0.3-0.8,1-1.3,1.9-1.3h1.9"/>
                                <path d="M445.8,190.6h-21c-0.6,0-1-0.4-1-1v-1c0-0.6,0.4-1,1-1h21c0.6,0,1,0.4,1,1v1      C446.8,190.2,446.4,190.6,445.8,190.6z"/>
                                <path d="M430.8,193.6v5"/>
                                <path d="M435.8,193.6v5"/>
                                <path d="M440.8,193.6v5"/>
                            </g>
                            <path fill="#AAAAAA"
                                  fillRule="nonzero" d="M417.9,217.5l-0.5,0.6l0.1,0.1c0.3,0.3,1.2,1,2.6,1c1.6,0,2.6-1,2.6-2.3c0-2.8-4.2-2.2-4.2-4.2     c0-0.7,0.7-1.5,1.8-1.5c1.1,0,1.8,0.7,1.8,0.7l0.4-0.7l-0.1-0.1c-0.2-0.2-1-0.8-2.2-0.8c-1.6,0-2.6,1.1-2.6,2.3     c0,2.6,4.2,2.1,4.2,4.2c0,0.9-0.8,1.5-1.7,1.5C418.7,218.5,417.9,217.5,417.9,217.5z M424.2,219.1h0.8v-3h1l2,3h1l-2.3-3.4v0     l2.1-2.6h-1l-1.8,2.3h-1v-4.7h-0.8V219.1z M430.2,217.7c0,1.3,0.9,1.5,1.4,1.5c0.2,0,0.3,0,0.3,0v-0.7l-0.1,0c0,0,0,0-0.1,0     c-0.3,0-0.7-0.1-0.7-0.9v-6.8h-0.8V217.7z M432.8,216.1c0,1.9,1.4,3.2,3.2,3.2c1.4,0,2.2-0.8,2.2-0.8l-0.4-0.6l-0.1,0.1     c-0.2,0.2-0.9,0.6-1.7,0.6c-1.2,0-2.3-0.8-2.3-2.4h4.6l0-0.3c0-0.1,0-0.1,0-0.1c0-1.5-0.9-2.8-2.5-2.8     C434.1,212.9,432.8,214.1,432.8,216.1z M433.7,215.5c0.2-1.2,1.1-1.8,2.1-1.8c0.9,0,1.7,0.6,1.7,1.8H433.7z M439.8,221.5h0.8     v-2.9l0-0.2c0-0.2,0-0.3,0-0.3h0l0,0.1c0.1,0.2,0.7,1.1,1.9,1.1c1.6,0,2.7-1.2,2.7-3.2c0-1.9-1-3.2-2.6-3.2     c-1.6,0-2.1,1.2-2.1,1.2h0l0-0.2c0-0.1,0-0.2,0-0.3v-0.6h-0.8V221.5z M440.6,216.1c0-1.7,0.9-2.4,2-2.4c1.1,0,1.9,0.9,1.9,2.4     c0,1.5-0.9,2.4-1.9,2.4C441.3,218.5,440.6,217.3,440.6,216.1z M449.7,218.1l0.5-0.6l0.1,0.1c0.2,0.2,1,0.9,2.1,0.9     c1,0,1.9-0.7,1.9-1.8c0-1.2-1-1.8-2.1-1.8h-0.6l-0.2-0.5l2.1-2.5c0.2-0.3,0.4-0.5,0.4-0.5v0l-0.1,0c-0.1,0-0.3,0-0.5,0h-3.2v-0.7     h4.9v0.6l-2.5,2.9c1.1,0.1,2.6,0.7,2.6,2.5c0,1.4-1.2,2.6-2.8,2.6C450.7,219.2,449.7,218.1,449.7,218.1z"/>
                        </animated.g>
                    </g>
                </g>
            </svg>
        </div>
    );
};
