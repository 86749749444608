import React, { Component } from 'react';
import { FranczyzyDataProps } from '@content/types/franczyzy';
import { ConfiguratorActions } from '@store/actions';
import { ConfiguratorStateWithSelectors } from '@store/configurator/types';
import Page from '@components/layout/Page';
import Container from '@components/layout/Container';
import FranczyzyHero from '@components/franczyzy/FranczyzyHero';
import FranczyzyHowItWork from '@components/franczyzy/sections/FranczyzyHowItWork';
import FranczyzyBullets from '@components/franczyzy/sections/FranczyzyBullets';
import FranczyzyMosaic from '@components/franczyzy/FranczyzyMosaic';
import ProductsFourInRow from '@components/products/ProductsFourInRow';
import FranczyzySaveTime from '@components/franczyzy/sections/FranczyzySaveTime';
import FranczyzySupport from '@components/franczyzy/sections/FranczyzySupport';
import FranczyzyForm from '@components/franczyzy/sections/FranczyzyForm';
import LoaderCover from '@components/common/LoaderCover';
import FranczyzyAppointment from '@components/franczyzy/sections/FranczyzyAppointment';

import * as utils from '@utils';

export interface FranczyzyProps extends FranczyzyDataProps {
  configuratorActions: typeof ConfiguratorActions;
  configurator: ConfiguratorStateWithSelectors;
  langcode: string;
  urls: Array<string>;
}

class FranczyzyComponent extends Component<FranczyzyProps> {
  componentDidMount() {
    this.props.configuratorActions.setLoading(false);
  }

  render() {
    return (
      <Page>
        <FranczyzyHero {...this.props} langcode={this.props.langcode} />

        <Container>
          <FranczyzyHowItWork data={this.props.howWorks} />
          <FranczyzyBullets
            bullets={this.props.sectionBullet1}
            bulletsStart={true}
            circles={[
              { position: 0, rotate: 0, size: 'medium' },
              { position: 3, rotate: 0, size: 'medium' },
            ]}
            langcode={this.props.langcode}
            urls={this.props.urls}
          />
          <FranczyzyMosaic
            data={this.props.sectionRemote}
            title={utils.SafeHtml(this.props.sectionRemote.title)}
            right={true}
            circle={true}
          />
          <ProductsFourInRow data={this.props.sectionRemoteIcons} />
          <FranczyzyBullets
            bullets={this.props.sectionBullet2}
            title={this.props.bulletsTitle1}
            reverse={true}
            circles={[{ position: 1, rotate: 0, size: 'medium' }]}
            langcode={this.props.langcode}
            urls={this.props.urls}
          />
          <FranczyzyBullets
            bullets={this.props.sectionBullet3}
            title={this.props.bulletsTitle2}
            bulletsEnd={true}
            circles={[{ position: 0, rotate: 0, size: 'medium' }]}
            langcode={this.props.langcode}
            urls={this.props.urls}
          />
          <FranczyzyAppointment data={this.props.appointment} />
          <FranczyzySaveTime title={this.props.saveTime.title} icons={this.props.saveTime.icons} />
          <FranczyzyMosaic
            data={this.props.whatTerminal}
            title={utils.SafeHtml(this.props.whatTerminal.title)}
          />
          <FranczyzySupport title={this.props.support.title} icons={this.props.support.icons} />
          <FranczyzyForm {...this.props} />
          <LoaderCover active={this.props.configurator.loading} />
        </Container>
      </Page>
    );
  }
}

export default FranczyzyComponent;
