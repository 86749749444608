/* eslint-disable @typescript-eslint/camelcase */
import React, { Component } from 'react';

// === Helpers === //
import styled from '@emotion/styled';
import { ConfiguratorActions } from '@store/actions';
import * as utils from '@utils';
import * as Yup from 'yup';

// === Config === //
import { respondFrom, breakpoints, css } from '@styles';

// === Components === //
import { Formik, FormikActions, Form } from 'formik';
import { DemoFormInput } from '@components/demo/DemoFormInput';
import { DemoFormCheckbox } from '@components/demo/DemoFormCheckbox';
import Button from '@components/common/Button';
import Tooltip from '@components/common/Tooltip';
import Modal from '@components/common/Modal';

// === Types === //
import { FranczyzyForm } from '@content/types/franczyzy';

const FranczyzyFormWrapper = styled.div`
  margin-bottom: 120px;
  margin-top: 80px;

  h2 {
    text-align: center;
    margin-bottom: 50px;
  }
`;

const FranczyzyFormInner = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: 420px;
      margin: auto;
    `
  )}
`;

const Row = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      display: flex;
      justify-content: space-between;
      margin-top: -24px;

      > label {
        max-width: 46%;
      }
    `
  )}
`;

const ButtonWrapper = styled.div`
  display: inline-block;
`;

interface FranczyzyFormProps {
  configuratorActions: typeof ConfiguratorActions;
  form: FranczyzyForm;
}

export interface FranchaiseFormValues {
  name: string;
  email: string;
  phone: string;
  accept: boolean;
  web: string;
  shopsQuantity: string;
  branch: string;
}

class FranczyzyFormComponent extends Component<FranczyzyFormProps> {
  state = {
    accept: false,
    error: false,
    success: false,
  };

  onChangeCheckbox = value => this.setState({ accept: !value });

  render() {
    const validationSchema = Yup.object().shape({
      name: Yup.string()
        // .matches(/^[0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ'-&,.!\s]{0,100}$/, { message: 'Niepoprawny format' })
        .required(this.props.form.text1),
      email: Yup.string()
        .email(this.props.form.text9)
        .required(this.props.form.text3),
      phone: Yup.string()
        .matches(/^[0-9]{9,}$/, { message: this.props.form.text8 })
        .required(this.props.form.text2),
      web: Yup.string()
        // .matches(/^[0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ'-\s]{0,100}$/, {
        //   message: 'Niepoprawny format nazwy sieci',
        // })
        .required(this.props.form.text4),
      shopsQuantity: Yup.string()
        .matches(/^[1-9][0-9]{0,8}$/, { message: this.props.form.text10 })
        .required(this.props.form.text5),
      branch: Yup.string()
        // .matches(/^[0-9a-zA-ZżźćńółęąśŻŹĆĄŚĘŁÓŃ'-\s]{0,100}$/, {
        //   message: 'Niepoprawny format nazwy branzy',
        // })
        .required(this.props.form.text6),
    });
    return (
      <FranczyzyFormWrapper id="formularz">
        <h2>{utils.SafeHtml(this.props.form.title)}</h2>

        <FranczyzyFormInner>
          <Formik
            initialValues={{
              name: '',
              email: '',
              phone: '',
              web: '',
              shopsQuantity: '',
              branch: '',
              accept: false,
            }}
            validationSchema={validationSchema}
            onSubmit={(
              values: FranchaiseFormValues,
              actions: FormikActions<FranchaiseFormValues>
            ) => {
              this.props.configuratorActions.setLoading(true);

              this.setState({ error: false, success: false }, () => {
                fetch(`${process.env.IPOS_BE_API_URL}/contact/franchise`, {
                  method: 'POST',
                  body: JSON.stringify({
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    title: values.web,
                    number_of_stores: values.shopsQuantity,
                    industries: values.branch,
                    agreement: values.accept,
                  }),
                  headers: {
                    'Content-Type': 'application/json',
                  },
                }).then(response => {
                  this.props.configuratorActions.setLoading(false);

                  if (response.status === 204) {
                    this.setState({ success: true }, () => {
                      actions.resetForm();
                    });
                  } else {
                    this.setState({ error: true });
                  }
                });
              });
              actions.setSubmitting(false);
            }}
            render={() => (
              <Form autoComplete="on">
                <DemoFormInput
                  name="name"
                  labelText={this.props.form.name}
                  type="text"
                  maxlength={100}
                />
                <DemoFormInput
                  name="phone"
                  labelText={this.props.form.phone}
                  type="phone"
                  maxlength={9}
                />
                <DemoFormInput
                  name="email"
                  labelText={this.props.form.email}
                  type="email"
                  maxlength={100}
                />
                <DemoFormInput
                  name="web"
                  labelText={this.props.form.network}
                  type="text"
                  maxlength={100}
                />
                <Row>
                  <DemoFormInput
                    name="shopsQuantity"
                    labelText={this.props.form.shopsNumber}
                    type="text"
                    maxlength={8}
                  />
                  <DemoFormInput
                    name="branch"
                    labelText={this.props.form.branch}
                    type="text"
                    maxlength={100}
                  />
                </Row>
                <DemoFormCheckbox
                  name="accept"
                  labelText={this.props.form.input}
                  onChangeCheckbox={this.onChangeCheckbox}
                />

                <ButtonWrapper data-tip="React-tooltip" data-for="franczyzyButton">
                  <Button
                    icon="arrow-right"
                    type="submit"
                    disabled={!this.state.accept ? true : false}
                  >
                    {this.props.form.submitButton}
                  </Button>
                </ButtonWrapper>
              </Form>
            )}
          />
          <Modal
            isOpened={this.state.error || this.state.success}
            content={this.state.success ? this.props.form.modal1 : this.props.form.modal2}
          />
          <Tooltip
            className={this.state.accept ? 'is-hidden' : ''}
            text={this.props.form.text7}
            theme="light"
            id="franczyzyButton"
          />
        </FranczyzyFormInner>
      </FranczyzyFormWrapper>
    );
  }
}

export default FranczyzyFormComponent;
