import React from 'react';
import styled from '@emotion/styled';
import BranchBullets from '@components/branches/sections/BranchBullets';
import { BranchBulletsItem, BranchBulletsCirclesAnimation } from '@content/types/branch';
import * as utils from '@utils';

const FranczyzyBulletsTitle = styled.div`
  text-align: center;
  margin-bottom: 50px;
  margin-top: 50px;
`;

interface FranczyzyBulletsProps {
  title?: string;
  reverse?: boolean;
  bullets: BranchBulletsItem[];
  bulletsStart?: boolean;
  bulletsEnd?: boolean;
  circles?: BranchBulletsCirclesAnimation[];
  langcode: string;
  urls: Array<string>;
}

const FranczyzyBullets = (props: FranczyzyBulletsProps) => {
  return (
    <div>
      {props.title && props.title !== '' && (
        <FranczyzyBulletsTitle>
          <h2>{utils.SafeHtml(props.title)}</h2>
        </FranczyzyBulletsTitle>
      )}
      <BranchBullets
        reverse={props.reverse}
        bullets={props.bullets}
        noLastDot={true}
        bulletsStart={props.bulletsStart}
        bulletsEnd={props.bulletsEnd}
        circles={props.circles}
        langcode={props.langcode}
        urls={props.urls}
      />
    </div>
  );
};

export default FranczyzyBullets;
