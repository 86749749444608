import React, { useState } from 'react';
import styled from '@emotion/styled';
import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import AnimationCircle from '@animations/AnimationCircle';
import Container from '@components/layout/Container';
import { breakpoints, colors, respondFrom, css, fluidRange, respondTo } from '@styles';
import ButtonLink from '@components/common/ButtonLink';
import calendarIcon from '@assets/svg/calendar.svg';
import { FranczyzyAppointment as FranczyzyAppointmentData } from '@content/types/franczyzy';
import * as utils from '@utils';
import Image from 'gatsby-image';

const StyledFranczyzyAppointment = styled.section`
  position: relative;
`;

const StyledContent = styled.div`
  ${respondTo(
    breakpoints.md,
    css`
      margin-left: -32px;
      margin-right: -32px;
    `
  )};
  ${respondFrom(
    breakpoints.lg,
    css`
      padding-top: 150px;
      padding-right: 75px;
      min-height: 630px;
    `
  )};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${colors.grayNurse};
  ${respondFrom(
    breakpoints.lg,
    css`
      flex-direction: row;
      align-items: flex-end;
    `
  )};
`;

const StyledCopy = styled.div`
  flex: 1;
  padding: 40px 20px;
  p {
    font-size: 16px;
    line-height: 1.5em;
  }
  ${respondFrom(
    breakpoints.md,
    css`
      ${fluidRange('padding', '40px', '80px')}
      ${fluidRange('padding-bottom', '40px', '60px')}
    `
  )};
`;

const StyledImage = styled.div`
  flex: 1;
  width: 100%;
  padding: 0 20px;
  img {
    display: block;
    object-fit: contain !important;
  }
  > div {
    max-height: 280px;
  }
  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: -150px;
      padding: 0 24px;
      > div {
        max-height: 560px;
      }
    `
  )};
`;

const StyledButtonLink = styled(ButtonLink)`
  align-self: flex-start;
  margin-top: 30px;
  .button-text {
    padding-left: 25px;
    img {
      position: absolute;
      left: -10px;
      top: 50%;
      margin-top: -15px;
    }
  }
  ${respondFrom(
    breakpoints.md,
    css`
      .button-text {
        img {
          left: -25px;
        }
      }
    `
  )};
`;

const StyledAnimationCircleWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
`;

interface FranczyzyAppointmentProps {
  data?: FranczyzyAppointmentData;
}

const FranczyzyAppointment = ({ data }: FranczyzyAppointmentProps) => {
  const [visibility, setVisibility] = useState(false);

  if (!data) return null;

  return (
    <StyledFranczyzyAppointment>
      <AnimationTriggerWaypoint onChange={setVisibility}>
        <Container>
          <StyledContent>
            <StyledWrapper>
              <StyledCopy>
                {data.header && <h3>{data.header}</h3>}
                {data.text && utils.SafeHtml(data.text)}
                {data.button && (
                  <StyledButtonLink
                    to={data.button.url}
                    icon="arrow-right"
                    className="no-parent-hovering"
                    extend
                  >
                    <img src={calendarIcon} alt="calendar" />
                    {data.button.title}
                  </StyledButtonLink>
                )}
              </StyledCopy>
              <StyledImage>
                {data.image && data.image.childImageSharp && (
                  <Image {...data.image.childImageSharp} />
                )}
              </StyledImage>
            </StyledWrapper>
          </StyledContent>
        </Container>
        <StyledAnimationCircleWrapper>
          <AnimationCircle
            sectionVisible={visibility}
            className="size-large"
            animateReverse={true}
          />
        </StyledAnimationCircleWrapper>
      </AnimationTriggerWaypoint>
    </StyledFranczyzyAppointment>
  );
};

export default FranczyzyAppointment;
