import React from 'react';
import styled from '@emotion/styled';
import ProductsFourInRow from '@components/products/ProductsFourInRow';
import { SectionRemoteIcons } from '@content/types/franczyzy';
import * as utils from '@utils';

const FranczyzySupportTitle = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

interface FranczyzySupportProps {
  title: string;
  icons: SectionRemoteIcons[];
}

const FranczyzySupport = (props: FranczyzySupportProps) => {
  return (
    <div>
      {props.title && props.title !== '' && (
        <FranczyzySupportTitle>{utils.SafeHtml(props.title)}</FranczyzySupportTitle>
      )}
      <ProductsFourInRow data={props.icons} />
    </div>
  );
};

export default FranczyzySupport;
