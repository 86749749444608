import get from 'lodash.get';
import { FileImageSharp } from '@content/types/api';
import { FranczyzyDataProps, FranczyzyBullets } from '@content/types/franczyzy';

export const franczyzyTransformers = (page): FranczyzyDataProps => {
  const franczyzyLanguages = {};

  page.allSiteSettingEntityFranchise.edges.forEach(edge => {
    const data = edge.node;
    const hero =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__branches_hero'
      );
    const bullets: FranczyzyBullets[] = [];
    data.relationships.field_components.forEach(component => {
      if (component.__typename === 'paragraph__bullets_container') {
        bullets.push(component);
      }
    });
    const texts: Array<string> = [];
    data.relationships.field_components.forEach(component => {
      if (component.__typename === 'paragraph__news_text') {
        texts.push(component);
      }
    });
    const whatsGoing =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__branches_check_what_s_going_on'
      );
    const recommended =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__branches_recommendation'
      );
    const logos =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__logos'
      );
    const howItWorks =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__how_it_works'
      );
    const titleIcons: any[] = [];
    data.relationships.field_components.forEach(component => {
      if (component.__typename === 'paragraph__title_icons') {
        titleIcons.push(component);
      }
    });
    const appointment =
      data.relationships &&
      data.relationships.field_components &&
      data.relationships.field_components.find(
        component => component.__typename === 'paragraph__make_an_appointment'
      );

    franczyzyLanguages[data.langcode] = {
      seo: {
        title: get(data, 'field_meta_title', ''),
        description: get(data, 'field_meta_description', ''),
        image: get(data, 'relationships.field_main_image.localFile.publicURL'),
      },
      heroImage: get(hero, 'relationships.field_branches_image.localFile', null),
      heroImageMobile: get(hero, 'relationships.field_branches_image_mobile.localFile', null),
      heroImageAlt: get(hero, 'field_branches_image.alt', ''),
      heroTitle: get(hero, 'field_paragraph_headline.value', ''),
      heroText: get(hero, 'field_branches_text.value', ''),
      buttonText: get(hero, 'field_branches_button_text', ''),
      video: get(hero, 'field_hero_1_video', ''),
      videoThumbnail: get(hero, 'relationships.field_video_thumbnail.localFile', null),
      videoThumbnailAlt: get(hero, 'field_video_thumbnail.alt', ''),
      heroAdditionalText: get(logos, 'field_logos_text.value', ''),
      heroAdditionalImages: logos.relationships.field_logos_logos.map((img, index) => ({
        img: get(img, 'localFile', null),
        alt: get(logos.field_logos_logos[index], 'alt', ''),
      })),

      howWorks: {
        imageScreen: get(howItWorks, 'relationships.field_image.localFile', null),
        imageTerminal1: get(howItWorks, 'relationships.field_image_1.localFile', null),
        imageTerminal2: get(howItWorks, 'relationships.field_image_2.localFile', null),
        imageTerminal3: get(howItWorks, 'relationships.field_image_3.localFile', null),
        icon: get(howItWorks, 'relationships.field_ico_1.localFile.publicURL', ''),
        title: get(howItWorks, 'field_paragraph_headline.value', ''),
        text1: get(howItWorks, 'field_text.value', ''),
        imageScreenAlt: get(howItWorks, 'field_image.alt', ''),
        iconAlt: get(howItWorks, 'field_ico_1.alt', ''),
        description: get(howItWorks, 'field_description.value', ''),
        imageTerminal1Alt: get(howItWorks, 'field_image_1.alt', ''),
        imageTerminal2Alt: get(howItWorks, 'field_image_2.alt', ''),
        imageTerminal3Alt: get(howItWorks, 'field_image_3.alt', ''),
        terminal1Text: get(howItWorks, 'field_short_text_1.value', ''),
        terminal2Text: get(howItWorks, 'field_short_text_2.value', ''),
        terminal3Text: get(howItWorks, 'field_short_text_3.value', ''),
      },

      sectionBullet1: bullets[0].relationships.field_bullets_item.map(bullet => ({
        paragraph: get(bullet, 'field_text.value', ''),
        image: get(bullet, 'relationships.field_image.localFile', null) as FileImageSharp,
        imageAlt: get(bullet, 'field_image.alt', ''),
        video: get(bullet, 'field_video', ''),
      })),
      sectionBullet2: bullets[1].relationships.field_bullets_item.map(bullet => ({
        paragraph: get(bullet, 'field_text.value', ''),
        image: get(bullet, 'relationships.field_image.localFile', null) as FileImageSharp,
        imageAlt: get(bullet, 'field_image.alt', ''),
        video: get(bullet, 'field_video', ''),
      })),
      sectionBullet3: bullets[2].relationships.field_bullets_item.map(bullet => ({
        paragraph: get(bullet, 'field_text.value', ''),
        image: get(bullet, 'relationships.field_image.localFile', null) as FileImageSharp,
        imageAlt: get(bullet, 'field_image.alt', ''),
        video: get(bullet, 'field_video', ''),
      })),
      bulletsTitle1: get(texts[0], 'field_nt_text', ''),
      bulletsTitle2: get(texts[1], 'field_nt_text', ''),

      sectionRemote: {
        title: get(whatsGoing, 'field_paragraph_headline.value', ''),
        buttonText: get(whatsGoing, 'field_branches_button_text', ''),
        imageAlt: get(whatsGoing, 'field_branches_image.alt', ''),
        paragraph: get(whatsGoing, 'field_branches_text.value', ''),
        image: get(whatsGoing, 'relationships.field_branches_image.localFile', null),
      },
      sectionRemoteIcons: [
        {
          text: get(whatsGoing, 'field_short_text_1.value', ''),
          image: get(whatsGoing, 'relationships.field_ico_1.localFile.publicURL', ''),
        },
        {
          text: get(whatsGoing, 'field_short_text_2.value', ''),
          image: get(whatsGoing, 'relationships.field_ico_2.localFile.publicURL', ''),
        },
        {
          text: get(whatsGoing, 'field_short_text_3.value', ''),
          image: get(whatsGoing, 'relationships.field_ico_3.localFile.publicURL', ''),
        },
        {
          text: get(whatsGoing, 'field_short_text_4.value', ''),
          image: get(whatsGoing, 'relationships.field_ico_4.localFile.publicURL', ''),
        },
      ],

      saveTime: {
        title: get(titleIcons[0], 'field_paragraph_headline.value', ''),
        icons: titleIcons[0].relationships.field_ico_and_text.map(icons => ({
          image: get(icons, 'relationships.field_ico.localFile.publicURL', ''),
          text: get(icons, 'field_text.value', ''),
        })),
      },

      whatTerminal: {
        title: get(recommended, 'field_paragraph_headline.value', ''),
        buttonText: get(recommended, 'field_nc_button_text', ''),
        imageAlt: get(recommended, 'field_branches_image.alt', ''),
        paragraph: get(recommended, 'field_branches_text.value', ''),
        image: get(recommended, 'relationships.field_branches_image.localFile', null),
      },

      support: {
        title: get(titleIcons[1], 'field_paragraph_headline.value', ''),
        icons: titleIcons[1].relationships.field_ico_and_text.map(icons => ({
          image: get(icons, 'relationships.field_ico.localFile.publicURL', ''),
          text: get(icons, 'field_text.value', ''),
        })),
      },

      form: {
        title: get(data, 'field_fr_form_header', ''),
        name: get(data, 'field_fr_form_name', ''),
        branch: get(data, 'field_fr_form_branch', ''),
        email: get(data, 'field_fr_form_email', ''),
        network: get(data, 'field_fr_form_network_name', ''),
        phone: get(data, 'field_fr_form_phone', ''),
        shopsNumber: get(data, 'field_fr_form_shops_number', ''),
        submitButton: get(data, 'field_settings_button_text', ''),
        text1: get(data, 'field_settings_text_1', ''),
        text2: get(data, 'field_settings_text_2', ''),
        text3: get(data, 'field_settings_text_3', ''),
        text4: get(data, 'field_settings_text_4', ''),
        text5: get(data, 'field_settings_text_5', ''),
        text6: get(data, 'field_settings_text_6', ''),
        text7: get(data, 'field_settings_text_7', ''),
        text8: get(data, 'field_settings_text_8', ''),
        text9: get(data, 'field_settings_text_9', ''),
        text10: get(data, 'field_settings_text_10', ''),
        input: get(data, 'field_text_1.value', ''),
        modal1: get(data, 'field_modal_1.value', ''),
        modal2: get(data, 'field_modal_2.value', ''),
      },

      appointment: appointment && {
        button: {
          title: get(
            appointment,
            'relationships.field_appointment_button.field_link_with_text_text'
          ),
          url: get(appointment, 'relationships.field_appointment_button.field_link_with_text_link'),
        },
        header: get(appointment, 'field_appointment_header'),
        text: get(appointment, 'field_appointment_text.value'),
        icon: get(appointment, 'relationships.field_appointment_icon.localFile.publicURL'),
        image: get(
          appointment,
          'relationships.field_appointment_image.localFile',
          null
        ) as FileImageSharp,
      },
    };
  });

  return franczyzyLanguages;
};
