import { graphql, useStaticQuery } from 'gatsby';
import { FranczyzyDataProps } from '@content/types/franczyzy';
import * as franczyzyTransformers from '@content/transformers/FranczyzyTransformers';

const FranczyzyQuery = (): FranczyzyDataProps => {
  const franczyzyQuery = useStaticQuery(graphql`
    query {
      allSiteSettingEntityFranchise {
        edges {
          node {
            langcode
            field_fr_form_branch
            field_fr_form_email
            field_fr_form_header
            field_fr_form_name
            field_fr_form_network_name
            field_fr_form_phone
            field_fr_form_shops_number
            field_settings_button_text
            field_settings_text_1
            field_settings_text_10
            field_settings_text_2
            field_settings_text_3
            field_settings_text_4
            field_settings_text_5
            field_settings_text_6
            field_settings_text_7
            field_settings_text_8
            field_settings_text_9
            field_text_1 {
              value
            }
            field_meta_description
            field_meta_title
            field_modal_1 {
              value
            }
            field_modal_2 {
              value
            }
            relationships {
              field_main_image {
                localFile {
                  publicURL
                }
              }
              field_components {
                ... on paragraph__branches_check_what_s_going_on {
                  id
                  field_branches_image {
                    alt
                  }
                  field_branches_button_text
                  field_branches_text {
                    value
                  }
                  field_paragraph_headline {
                    value
                  }
                  field_short_text_1 {
                    value
                  }
                  field_short_text_2 {
                    value
                  }
                  field_short_text_3 {
                    value
                  }
                  field_short_text_4 {
                    value
                  }
                  relationships {
                    field_branches_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 750, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_ico_1 {
                      localFile {
                        publicURL
                      }
                    }
                    field_ico_2 {
                      localFile {
                        publicURL
                      }
                    }
                    field_ico_3 {
                      localFile {
                        publicURL
                      }
                    }
                    field_ico_4 {
                      localFile {
                        publicURL
                      }
                    }
                  }
                }
                ... on paragraph__branches_hero {
                  id
                  field_branches_button_text
                  field_hero_1_video
                  field_video_thumbnail {
                    alt
                  }
                  field_branches_image {
                    alt
                  }
                  field_branches_image_mobile {
                    alt
                  }
                  field_branches_text {
                    value
                  }
                  field_paragraph_headline {
                    value
                  }
                  relationships {
                    field_branches_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 1600, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_branches_image_mobile {
                      localFile {
                        childImageSharp {
                          fixed(width: 800, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_video_thumbnail {
                      localFile {
                        childImageSharp {
                          fixed(width: 900, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__branches_recommendation {
                  id
                  field_branches_image {
                    alt
                  }
                  field_branches_text {
                    value
                  }
                  field_nc_button_text
                  field_is_online
                  field_paragraph_headline {
                    value
                  }
                  relationships {
                    field_branches_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 750, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__bullets_container {
                  id
                  relationships {
                    field_bullets_item {
                      field_image {
                        alt
                      }
                      field_text {
                        value
                      }
                      field_video
                      relationships {
                        field_image {
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 475, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__news_text {
                  id
                  field_nt_text
                }
                ... on paragraph__logos {
                  field_logos_logos {
                    alt
                  }
                  field_logos_text {
                    value
                  }
                  relationships {
                    field_logos_logos {
                      localFile {
                        childImageSharp {
                          fixed(width: 125, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__how_it_works {
                  field_description {
                    value
                  }
                  field_ico_1 {
                    alt
                  }
                  field_image {
                    alt
                  }
                  field_image_1 {
                    alt
                  }
                  field_image_2 {
                    alt
                  }
                  field_image_3 {
                    alt
                  }
                  field_paragraph_headline {
                    value
                  }
                  field_short_text_1 {
                    value
                  }
                  field_short_text_2 {
                    value
                  }
                  field_short_text_3 {
                    value
                  }
                  field_text {
                    value
                  }
                  relationships {
                    field_ico_1 {
                      localFile {
                        publicURL
                      }
                    }
                    field_image {
                      localFile {
                        childImageSharp {
                          fixed(width: 475, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_image_1 {
                      localFile {
                        childImageSharp {
                          fixed(width: 245, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_image_2 {
                      localFile {
                        childImageSharp {
                          fixed(width: 245, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    field_image_3 {
                      localFile {
                        childImageSharp {
                          fixed(width: 245, quality: 100) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__title_icons {
                  field_paragraph_headline {
                    value
                  }
                  relationships {
                    field_ico_and_text {
                      field_ico {
                        alt
                      }
                      field_text {
                        value
                      }
                      relationships {
                        field_ico {
                          localFile {
                            publicURL
                          }
                        }
                      }
                    }
                  }
                }
                ... on paragraph__make_an_appointment {
                  id
                  field_appointment_header
                  field_appointment_text {
                    value
                  }
                  relationships {
                    field_appointment_button {
                      field_link_with_text_text
                      field_link_with_text_link
                    }
                    field_appointment_icon {
                      localFile {
                        publicURL
                      }
                    }
                    field_appointment_image {
                      localFile {
                        childImageSharp {
                          fluid(maxWidth: 500, quality: 100) {
                            ...GatsbyImageSharpFluid
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return franczyzyTransformers.franczyzyTransformers(franczyzyQuery);
};

export default FranczyzyQuery;
