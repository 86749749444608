import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import { SectionRemote } from '@content/types/franczyzy';

import ProductsMosaic from '@components/products/ProductsMosaic';
import ButtonLink from '@components/common/ButtonLink';
import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import AnimationCircle from '@animations/AnimationCircle';
import * as utils from '@utils';

const FranczyzyMosaicTitle = styled.div`
  max-width: 630px;
  margin: 50px auto;
  text-align: center;
`;

const FranczyzyMosaicInner = styled.div`
  display: flex;
  flex-flow: column;
  background-color: ${colors.grayNurse};
  padding: 20px;
  width: 100%;

  ${respondFrom(
    breakpoints.md,
    css`
      padding: 80px 100px;
    `
  )}
`;

const AnimationCircleWrapper = styled.div`
  position: absolute;
  top: -80px;
  right: -300px;
  z-index: -1;
`;

interface FranczyzyMosaicProps {
  data: SectionRemote;
  title: string | React.ReactElement;
  right?: boolean;
  circle?: boolean;
}

class FranczyzyMosaic extends Component<FranczyzyMosaicProps> {
  state = {
    isVisible: false,
  };

  render() {
    const { data, title, right, circle } = this.props;

    return (
      <AnimationTriggerWaypoint onChange={() => this.setState({ isVisible: true })}>
        <FranczyzyMosaicTitle>
          <h2>{title}</h2>
        </FranczyzyMosaicTitle>
        <ProductsMosaic right={right} imageMain={data.image.childImageSharp}>
          <FranczyzyMosaicInner>
            {utils.SafeHtml(data.paragraph)}
            {data.buttonText && data.buttonText !== '' && (
              <ButtonLink to="#formularz" icon="arrow-right" extend={true}>
                {data.buttonText}
              </ButtonLink>
            )}
          </FranczyzyMosaicInner>

          {circle && (
            <AnimationCircleWrapper>
              <AnimationCircle
                sectionVisible={this.state.isVisible}
                className="size-large"
                animateReverse={true}
              />
            </AnimationCircleWrapper>
          )}
        </ProductsMosaic>
      </AnimationTriggerWaypoint>
    );
  }
}

export default FranczyzyMosaic;
