import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import FranczyzyHowItWorkShop from '@components/franczyzy/sections/FranczyzyHowItWorksShop';
import Image from 'gatsby-image';
import { HowWorks } from '@content/types/franczyzy';
import AnimationFranczyzySection1 from '@animations/AnimationFranczyzySection1';
import AnimationTriggerWaypoint from '@animations/AnimationTriggerWaypoint';
import * as utils from '@utils';

const FranczyzyHowItWorkWrapper = styled.div`
  margin-top: 120px;
  margin-bottom: 50px;
`;

const FranczyzyHowItWorkTitle = styled.div`
  max-width: 770px;
  margin: auto;

  h2,
  p {
    ${respondFrom(
      breakpoints.lg,
      css`
        text-align: center;
      `
    )};
  }
`;

const FranczyzyHowWorkInner = styled.div`
  display: flex;
  flex-flow: column;
  margin-top: 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      justify-content: space-between;
      align-items: center;
      max-width: 1082px;
      margin: 50px auto 80px;
      position: relative;
    `
  )};
`;

const FranczyzyHowWorkScreen = styled.div`
  max-width: 100%;

  align-self: center;

  ${respondFrom(
    breakpoints.lg,
    css`
      max-width: ${(100 * 475) / 1082}%;
    `
  )};
`;

const FranczyzyHowWorkScreenTitle = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  ${respondFrom(
    breakpoints.lg,
    css`
      position: absolute;
      top: 100px;
      left: 0;
    `
  )};

  span {
    &:last-of-type {
      margin-left: 10px;
      color: ${colors.text.light};
    }
  }
`;

const FranczyzyHowWorkScreenImage = styled.div`
  margin-bottom: 30px;

  .gatsby-image-wrapper {
    max-width: 100%;
  }
`;

const FranczyzyHowWorkScreenText = styled.div`
  max-width: 460px;

  ${respondFrom(
    breakpoints.lg,
    css`
      position: absolute;
      bottom: 25px;
      left: 0;
    `
  )};
`;

const StyledAnimationFranczyzySection1 = styled(AnimationFranczyzySection1)`
  position: absolute;
  /* left: 45%; */
  right: 313px;
  top: 50%;
  transform: translate(0%, -53%);
  width: 27%;
  display: none;

  ${respondFrom(
    breakpoints.lg,
    css`
      display: block;
    `
  )};

  svg {
    /* width: 100%; */
  }
`;

interface FranczyzyHowItWorkProps {
  data: HowWorks;
}

const FranczyzyHowItWork = ({ data }: FranczyzyHowItWorkProps) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <FranczyzyHowItWorkWrapper>
      <FranczyzyHowItWorkTitle>{utils.SafeHtml(data.title)}</FranczyzyHowItWorkTitle>

      <AnimationTriggerWaypoint onChange={setVisibility}>
        <FranczyzyHowWorkInner>
          <FranczyzyHowWorkScreen>
            <FranczyzyHowWorkScreenTitle>
              <span>
                <img src={data.icon} alt={data.iconAlt} />
              </span>
              <span>{data.text1}</span>
            </FranczyzyHowWorkScreenTitle>
            <FranczyzyHowWorkScreenImage>
              <Image
                {...data.imageScreen.childImageSharp}
                imgStyle={{ objectFit: 'contain' }}
                alt={data.imageScreenAlt}
              />
            </FranczyzyHowWorkScreenImage>
            <FranczyzyHowWorkScreenText>
              {utils.SafeHtml(data.description)}
            </FranczyzyHowWorkScreenText>
          </FranczyzyHowWorkScreen>
          <div>
            <FranczyzyHowItWorkShop
              title={data.terminal1Text}
              image={data.imageTerminal1}
              imageAlt={data.imageTerminal1Alt}
            />
            <FranczyzyHowItWorkShop
              title={data.terminal2Text}
              image={data.imageTerminal2}
              imageAlt={data.imageTerminal2Alt}
            />
            <FranczyzyHowItWorkShop
              title={data.terminal3Text}
              image={data.imageTerminal3}
              imageAlt={data.imageTerminal3Alt}
            />
          </div>

          <StyledAnimationFranczyzySection1 sectionVisible={visibility} />
        </FranczyzyHowWorkInner>
      </AnimationTriggerWaypoint>
    </FranczyzyHowItWorkWrapper>
  );
};

export default FranczyzyHowItWork;
