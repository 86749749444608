import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, respondTo } from '@styles';
import { ImageSharp } from '@content/types/general';
import Image from 'gatsby-image';

import BasketIcon from '@assets/svg/sklep-franczyzy.svg';

const FranczyzyHowItWorksShopWrapper = styled.div`
  border: 1px solid ${colors.gray};
  border-radius: 23px;
  padding: 19px 24px 18px 30px;
  max-width: 325px;
  position: relative;
  min-height: 180px;
  margin: 15px auto;

  ${respondFrom(
    breakpoints.lg,
    css`
      padding: 19px 24px 18px 55px;
      position: relative;
      top: -15px;
    `
  )}

  .gatsby-image-wrapper {
    margin-right: 0;
    margin-left: auto;
  }
`;

const SideIcon = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -25px;
  right: auto;
  margin: auto;
  background-color: ${colors.white};

  height: 50%;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;

  img {
    margin-bottom: 15px;
  }

  p {
    margin: 0;
    color: ${colors.text.light};
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      right: 0;
      > * {
        display: none; /* icon contained in animation */
      }
    `
  )}
`;

interface FranczyzyHowItWorksShopProps {
  title: string;
  image: ImageSharp;
  imageAlt: string;
}

const FranczyzyHowItWorksShop = ({ title, image, imageAlt }: FranczyzyHowItWorksShopProps) => {
  return (
    <FranczyzyHowItWorksShopWrapper>
      <SideIcon>
        <img src={BasketIcon} alt="" />
        <p>{title}</p>
      </SideIcon>

      <div>
        <Image {...image.childImageSharp} imgStyle={{ imageFit: 'contain' }} alt={imageAlt} />
      </div>
    </FranczyzyHowItWorksShopWrapper>
  );
};

export default FranczyzyHowItWorksShop;
