import React from 'react';

import FranczyzyContainer from '@containers/franczyzy';
import useFranczyzyQuery from '@graphql/queries/FranczyzyQuery';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import { PageContext } from '@content/types/pageContext';

interface FranchaiseProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: FranchaiseProps) => {
  const franczyzyData = useFranczyzyQuery()[props.pageContext.langcode];

  return (
    <>
      <MetaPage title={franczyzyData.seo.title} description={franczyzyData.seo.description} image={franczyzyData.seo.image} />
      <DataLayer location={props.location} />
      <FranczyzyContainer
        {...franczyzyData}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </>
  );
};
