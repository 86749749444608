import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css } from '@styles';
import Image from 'gatsby-image';
import ArrowDown from '@components/common/ArrowDown';
import { FranczyzyLogos } from '@content/types/franczyzy';
import * as utils from '@utils';

const HeroAdditionalInfoComponent = styled.div`
  position: relative;
`;

const HeroAdditionalInfoWrapper = styled.div`
  background-color: ${colors.grayNurse};
  padding-left: 30px;
  padding-right: 30px;
  display: block;

  ${props =>
    respondFrom(
      breakpoints.lg,
      css`
        max-width: ${props.withVideo ? '45%' : '100%'};
      `
    )}

  ${respondFrom(
    breakpoints.xl,
    css`
      transform: translateY(-20px);
      padding-left: 100px;
      padding-right: 0;
      display: inline-block;
    `
  )}
`;

const HeroAdditionalInfoInner = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 0;
  color: ${colors.text.default};

  flex-flow: column;

  ${props =>
    respondFrom(
      breakpoints.lg,
      css`
        flex-flow: ${props.withVideo ? 'column' : 'row'};
      `
    )}

  h3 {
    margin: 0;
    margin-bottom: 20px;

    ${respondFrom(
      breakpoints.xl,
      css`
        margin-right: 50px;
        margin-bottom: 0;
      `
    )}
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    flex-flow: column;

    ${respondFrom(
      breakpoints.md,
      css`
        flex-flow: row;
      `
    )}

    ${respondFrom(
      breakpoints.lg,
      css`
        flex-wrap: wrap;
        row-gap: 15px;
        justify-content: center;
      `
    )}
  }

  li {
    margin: 15px 0;

    ${respondFrom(
      breakpoints.xl,
      css`
        margin: 0 10px;
      `
    )}
  }

  .gatsby-image-wrapper {
    max-height: 58px;
  }
`;

interface FranczyzyHeroAdditionalContentProps {
  text: string;
  images: FranczyzyLogos[];
  withVideo: boolean;
}

const FranczyzyHeroAdditionalContent = ({
  text,
  images,
  withVideo,
}: FranczyzyHeroAdditionalContentProps) => {
  return (
    <HeroAdditionalInfoComponent>
      <HeroAdditionalInfoWrapper withVideo={withVideo}>
        <HeroAdditionalInfoInner withVideo={withVideo}>
          {text && utils.SafeHtml(text)}
          <ul>
            {images.map((img, i) => (
              <li key={i}>
                {img.img && (
                  <Image
                    {...img.img.childImageSharp}
                    imgStyle={{ objectFit: 'contain' }}
                    alt={img.alt}
                  />
                )}
              </li>
            ))}
          </ul>
        </HeroAdditionalInfoInner>
      </HeroAdditionalInfoWrapper>
    </HeroAdditionalInfoComponent>
  );
};

export default FranczyzyHeroAdditionalContent;
