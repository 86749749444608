import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import FranczyzyComponent, { FranczyzyProps } from '@components/franczyzy';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DFranczyzyPropsProps extends FranczyzyProps {}
const DFranczyzy: React.FC<DFranczyzyPropsProps> = (props: DFranczyzyPropsProps) => (
  <FranczyzyComponent {...props} />
);

const FranczyzyConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DFranczyzy);

export default FranczyzyConnected;
