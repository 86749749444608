import React from 'react';
import styled from '@emotion/styled-base';

import { respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';
import { FranczyzyDataProps } from '@content/types/franczyzy';
import Hero from '@components/common/Hero';
import FranczyzyHeroAdditionalContent from '@components/franczyzy/FranczyzyHeroAdditionalContent';
import AnimationFranczyzyHero from '../../animations/AnimationFranczyzyHero';
import HeroVideoPlayer from '@components/common/HeroVideoPlayer';
import { ImageSharp } from '@content/types/general';
import AnimationArrowDown from '@animations/AnimationArrowDown';

const StyledAnimationFranczyzyHero = styled(AnimationFranczyzyHero)`
  display: none;

  ${respondFrom(
    breakpoints.md,
    css`
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(51%, 45%);
    `
  )}
`;

const StyledArrowDown = styled(AnimationArrowDown)`
  position: absolute;
  bottom: -250px;
  left: 0;
  right: 0;

  ${respondFrom(
    breakpoints.lg,
    css`
      bottom: -200px;
    `
  )}
`;

interface FranczyzyHeroProps extends FranczyzyDataProps {
  langcode: string;
  video?: string;
  videoThumbnail?: ImageSharp;
  videoThumbnailAlt?: string;
}

const FranczyzyHero = (props: FranczyzyHeroProps) => {
  return (
    <>
      <Hero
        size="medium"
        image={props.heroImage ? props.heroImage.childImageSharp : {}}
        imageMobile={props.heroImageMobile ? props.heroImageMobile.childImageSharp : {}}
        title={utils.SafeHtml(props.heroTitle)}
        paragraph={utils.SafeHtml(props.heroText)}
        buttonText={props.buttonText}
        noArrow={true}
        buttonRoute="#formularz"
        buttonOutside={true}
        heroTheme="dark"
        additionalContent={
          <>
            <StyledAnimationFranczyzyHero sectionVisible={true} />
            {props.video && (
              <HeroVideoPlayer
                videoUrl={props.video}
                videoThumbnail={props.videoThumbnail}
                videoThumbnailAlt={props.videoThumbnailAlt}
              />
            )}
            <StyledArrowDown />
          </>
        }
      />
      <FranczyzyHeroAdditionalContent
        text={props.heroAdditionalText}
        images={props.heroAdditionalImages}
        withVideo={!!props.video}
      />
    </>
  );
};

export default FranczyzyHero;
