import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@styles';
import ProductsFourInRow from '@components/products/ProductsFourInRow';
import { SectionRemoteIcons } from '@content/types/franczyzy';
import * as utils from '@utils';

const FranczyzySaveTimeTitle = styled.div`
  text-align: center;
  margin-bottom: 50px;
  margin-top: 60px;
`;

const FranczyzySaveTimeWrapper = styled.div`
  position: relative;
  padding-bottom: 120px;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1px;
    height: 100px;
    background-color: ${colors.green.dark};
  }
`;

interface FranczyzySaveTimeProps {
  title?: string;
  icons: SectionRemoteIcons[];
}

const FranczyzySaveTime = ({ title, icons }: FranczyzySaveTimeProps) => {
  const icons1 = icons.splice(0, 3);

  return (
    <FranczyzySaveTimeWrapper>
      {title && title !== '' && (
        <FranczyzySaveTimeTitle>
          <h2>{utils.SafeHtml(title)}</h2>
        </FranczyzySaveTimeTitle>
      )}
      <ProductsFourInRow data={icons1} />
      <ProductsFourInRow data={icons} />
    </FranczyzySaveTimeWrapper>
  );
};

export default FranczyzySaveTime;
